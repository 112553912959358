<template>
  <div
    class="elv-base-cell-text"
    :title="titleDesc"
    :style="{
      fontWeight: props.weight || 400,
      fontFamily: props.fontFamily || 'Barlow',
      fontSize: props.size || '13px'
    }"
    :class="props.align ? `elv-base-cell-text-${props.align}` : ''"
  >
    {{ props.text }}
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  text: string
  titleDesc?: string
  weight?: number
  size?: string
  fontFamily?: string
  align?: string
}>()
</script>
<style lang="scss">
.elv-base-cell-text {
  color: #0e0f11;
  font-family: 'Barlow';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}
</style>
